import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  deleteNatMeasure,
  getAllInstitutions,
  getRegistersManagement,
  getUsersByInstitution,
} from '../../../server/server';
import { notif } from '../../utils/util';
import { useUser } from '../../../auth/UseUser';
import ConfirmationModal from '../../modals/ConfirmationModal';
import HelpPageModal from '../../modals/HelpPageModal';
import HelpButton from '../../../common/HelpButton';
import { roleMap } from '../../../common/constaints';

function ManagementRegister() {
  const { user } = useUser();
  let navigate = useNavigate();
  const [term, setTerm] = useState('');
  const [institutions, setInstitutions] = useState<any>([]);
  const [registers, setRegisters] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCountState, setRowCountState] = useState(6);
  const [isLoading, setIsLoading] = useState(true);
  const [translation, setTranslation] = useState<any>({ id: 0, name: '' });
  const [defaultInstitution, setDefaultInstitution] = useState<any | null>();
  const [selectedUser, setSelectedUser] = useState<any | null>();
  const [allUsers, setAllUsers] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<any>(null);
  const [sortModel, setSortModel] = useState<any>([]);
  const [helpModal, setHelpModal] = useState(false);

  const closeHelpModal = () => {
    setHelpModal(false);
  };
  const handleInstChange = (e: any, prm: any) => {
    setDefaultInstitution(prm as any);
  };
  const handleUserChange = (e: any, prm: any) => {
    setSelectedUser(prm as any);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  const isAdmin = () => {
    return user?.roleView?.features?.includes('administration');
  };
  const deleteI = async () => {
    const resp = await deleteNatMeasure(deleteId);

    if (resp.status === 200) {
      notif('Пропис успешно обрисан', 'success');
      setRegisters((prevRows: any) =>
        prevRows.filter((row: any) => row?.id !== deleteId)
      );
      setDeleteModal(false);
    } else {
      notif('Пропис није обрисан', 'error');
    }
  };
  const deleteItem = React.useCallback(
    (id: GridRowId) => () => {
      setTimeout(() => {
        setDeleteId(id);
        setDeleteModal(true);
      });
    },
    []
  );
  const loadUsers = async () => {
    const resp = await getUsersByInstitution(defaultInstitution?.id ?? '');
    if (resp?.status) {
      setAllUsers(resp?.data);
    }
  };
  const handleSortChange = (model: any) => {
    console.log('handleSortChange', model);
    setSortModel(model);
  };
  const loadRegisters = async (offset: any = 0) => {
    console.log(user);
    const resp = await getRegistersManagement(
      pageSize,
      offset,
      term,
      defaultInstitution?.id ?? '',
      roleMap[(user?.roleView?.name ?? '') as keyof typeof roleMap],
      translation?.id === 0 ? undefined : translation?.id,
      selectedUser?.id ?? '',
      sortModel[0]?.field,
      sortModel[0]?.sort
    );

    if (resp?.status) {
      setIsLoading(false);
      setRegisters(resp?.data?.measures);
      setRowCountState(resp?.data?.total);
    }
  };

  const loadInstitutions = async () => {
    const resp = await getAllInstitutions(1);
    if (resp?.status) {
      setInstitutions(resp?.data);
    }
  };

  const setPageAndCall = (page: any) => {
    setPage(page);
    loadRegisters(page * pageSize);
  };
  const editItem = React.useCallback(
    (id: GridRowId) => () => {
      navigate('/register/edit?id=' + id);
    },
    []
  );

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'pid',
        headerName: 'Планирани ID',
        maxWidth: 170,
        minWidth: 60,
        flex: 1,
      },
      {
        field: 'fid',
        headerName: 'Постојећи ID',
        maxWidth: 170,
        minWidth: 60,
        flex: 1,
      },
      {
        field: 'title',
        headerName: 'Назив',
        minWidth: 240,
        flex: 1,
      },
      {
        field: 'user',
        headerName: 'Корисник',
        maxWidth: 120,
        minWidth: 80,
        flex: 1,
        renderCell: (params) => (
          <div>
            <Tooltip title={params.row.userName} enterDelay={300}>
              <div className="font-bold text-[#0f2765]">{params.row.user}</div>
            </Tooltip>
          </div>
        ),
      },
      {
        field: 'institution',
        headerName: 'Инстинтуција',
        maxWidth: 100,
        minWidth: 125,
        flex: 1,
        renderCell: (params) => (
          <div>
            <Tooltip title={params.row.institutionName} enterDelay={300}>
              <div className="font-bold text-[#0f2765]">
                {params.row.institution}
              </div>
            </Tooltip>
          </div>
        ),
      },
      {
        field: 'npi',
        headerName: 'НПАА',
        type: 'boolean',
        maxWidth: 60,
        flex: 1,
      },
      {
        field: 'ssp',
        headerName: 'ССП',
        type: 'boolean',
        maxWidth: 60,
        flex: 1,
      },
      {
        field: 'euRegister',
        headerName: 'ЕУ прописи',
        type: 'boolean',
        maxWidth: 120,
        flex: 1,
      },
      {
        field: 'np',
        headerName: 'Национални прописи',
        type: 'boolean',
        maxWidth: 120,
        flex: 1,
      },
      {
        field: 'ndocs',
        headerName: 'Везе са преговарачким документом',
        type: 'boolean',
        maxWidth: 120,
        flex: 1,
      },
      {
        field: 'institutions',
        headerName: 'Институције за спровођење',
        type: 'boolean',
        maxWidth: 120,
        flex: 1,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 120,
        getActions: (params) => {
          if (!params.row.editable) {
            return [];
          }
          if (isAdmin()) {
            return [
              <GridActionsCellItem
                icon={<FaTrash style={{ width: 20, height: 20 }} />}
                sx={{ width: 50, height: 50 }}
                label="Обриши"
                onClick={deleteItem(params.id)}
              />,
              <GridActionsCellItem
                icon={<FaEdit style={{ width: 20, height: 20 }} />}
                sx={{ width: 50, height: 50 }}
                label="Ажурирај"
                onClick={editItem(params.id)}
              />,
            ];
          }
          return [<GridActionsCellItem
            icon={<FaEdit style={{ width: 20, height: 20 }} />}
            sx={{ width: 50, height: 50 }}
            label="Ажурирај"
            onClick={editItem(params.id)}
          />];
        },
      },
    ],
    [deleteItem, editItem]
  );

  useEffect(() => {
    loadRegisters();
    loadInstitutions();
  }, []);

  useEffect(() => {
    loadUsers();
  }, [defaultInstitution]);

  useEffect(() => {
    console.log('useEffect triggered'); // Debugging log

    const loadAllData = async () => {
      console.log('Loading data...'); // Debugging log
      setIsLoading(true);
      await loadInstitutions();
      await loadUsers();
      await loadRegisters(page * pageSize);
    };

    loadAllData();
  }, [
    term,
    defaultInstitution,
    translation,
    selectedUser,
    pageSize,
    page,
    sortModel,
  ]);

  const translations = [
    { id: 1, name: 'Постојећи прописи' },
    { id: 2, name: 'Планирани прописи' },
  ];

  const handleTranslationsChange = (e: SelectChangeEvent) => {
    const selectedTranslation = translations.find(
      (t) => t.name === e.target.value
    );
    setTranslation(selectedTranslation ?? { id: 0, name: '' }); // Set the selected translation object
  };

  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full flex flex-col">
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">
          Управљање прописима
        </h1>
        <HelpButton onClick={() => setHelpModal(true)} />
      </div>
      <div className="rounded-lg border border-[#81848f3d] p-8 pb-4 mt-8 flex-grow">
        <h1 className="text-[#04438bae] text-3xl font-bold">Претрага</h1>
        <div className="w-full mt-6 flex items-center gap-12">
          <div className="flex flex-col items-start gap-3">
            <p className="w-[180px] text-sm text-[#404040]">
              По називу/идентификацији
            </p>
            <TextField
              label="Назив прописа"
              id="outlined-size-small"
              size="small"
              autoComplete="off"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            />
          </div>
          <div className="flex flex-col items-start gap-3">
            <p className="w-[160px] text-sm text-[#404040]">По институцији</p>
            <Autocomplete
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              value={defaultInstitution?.name}
              size="small"
              onChange={(event: any, newValue: any) =>
                handleInstChange(event, newValue)
              }
              id="controllable-states-demo"
              options={institutions}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Институција" />
              )}
            />
          </div>
          <div className="flex flex-col items-start gap-3">
            <p className="w-[160px] text-sm text-[#404040]">По кориснику</p>
            <Autocomplete
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              value={selectedUser?.name}
              size="small"
              disabled={!defaultInstitution?.name}
              onChange={(event: any, newValue: any) =>
                handleUserChange(event, newValue)
              }
              id="controllable-states-demo"
              options={allUsers}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Корисник" />
              )}
            />
          </div>
          <div className="flex flex-col items-start gap-3">
            <p className="w-[160px] text-sm text-[#404040]">
              По статусу прописа
            </p>
            <FormControl size="small" sx={{ minWidth: 160 }}>
              <InputLabel id="demo-select-small">Статус прописа</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={translation?.name ?? ' '}
                onChange={handleTranslationsChange}
                label="Статус прописа"
                className="capitalize"
              >
                <MenuItem value="">
                  <em>Сви</em>
                </MenuItem>
                {translations?.map((t) => {
                  return (
                    <MenuItem
                      value={t?.name}
                      className="capitalize"
                      key={t?.id}
                    >
                      {t?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="mt-5 min-h-[430px] w-full">
          <DataGrid
            rowCount={rowCountState}
            loading={isLoading}
            pagination
            disableColumnMenu
            paginationMode="server"
            onPageChange={(newPage) => setPageAndCall(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            page={page}
            pageSize={pageSize}
            rows={registers}
            columns={columns}
            autoHeight={true}
            getRowHeight={() => 'auto'}
            style={{ zIndex: 0 }}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            sortingMode="server"
            onSortModelChange={(model) => handleSortChange(model)}
            getRowClassName={(params) => {
              console.log(params?.row.outOfForce);
              return params?.row?.outOfForce
                ? 'redRow'
                : params?.row?.pirv
                  ? 'blueRow'
                  : '';
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје прописи са задатим критеријумом
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје прописи са задатим критеријумом
                </Stack>
              ),
            }}
          />
        </div>
      </div>
      <ConfirmationModal
        modalIsOpen={deleteModal}
        closeModal={closeDeleteModal}
        confirmation={deleteI}
        message={`Обриши пропис?`}
      />
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'managementRegister'}
      />
    </div>
  );
}

export default ManagementRegister;
